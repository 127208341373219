<script setup lang="ts">
const props = defineProps<{
  title?: string
  tag?: string
  icon?: string
  tabs?: string[]
  tabName?: string
}>()

const model = defineModel<number>()

const route = useRoute()

const backLink = computed(() => {
  const pathParts = route.path.split('/')
  return pathParts.length > 2 ? pathParts.slice(0, -1).join('/') : '/'
})

const titleIsLoading = computed(() => !props.title)
</script>

<template>
  <div class="flex flex-col gap-2 px-6 tab-underline">
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row items-center gap-2 text-gray-500">
        <div :i="`carbon-${icon}`" v-if="backLink === '/'" />
        <router-link v-else :to="backLink" class="hover:curser-pointer">
          <div i="carbon-arrow-left" />
        </router-link>
        <template v-if="titleIsLoading">
          <div class="h-[32px] bg-gray-200 rounded dark:bg-gray-700 w-48 animate-pulse" />
        </template>
        <CText v-else size="l" weight="semibold" data-testid="page-title" class="line-clamp-1 max-w-[400px]">{{
          title
        }}</CText>
        <EntityTypeTag v-if="tag" :display-name="tag" />
      </div>
      <div class="flex flex-row gap-3">
        <slot name="header_actions" />
      </div>
    </div>
    <OTab id="header-tabs" v-if="tabs" v-model="model" :list-tabs="tabs" :tab-name="tabName ?? 'tab'" />
    <div v-else class="w-full h-2" />
  </div>
</template>
