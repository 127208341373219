<script setup lang="ts">
import { onMounted, ref, useAttrs } from 'vue'
import { getQueryParam } from '~/utils/route'

/**
 * @modelValue : Number
 * (The selected Tab)
 *
 * @listTabs : Array<String>
 * (This list will render the defined tabs, don't forget to add localisation)
 *
 * @tabName : String
 * (The name of the query param to use for the tab)
 */

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
    default: 1,
  },
  listTabs: {
    type: Array<string>,
    required: true,
    default: [] as string[],
  },
  tabName: {
    type: String,
    required: false,
    default: 'tab',
  },
  class: {
    type: String,
    required: false,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: number): void
}>()

const router = useRouter()
const route = useRoute()

const underlineStyle = ref({
  width: '0px',
  transform: 'translateX(0px)',
})

const attrs = useAttrs()

function getTabElement(index: number) {
  if (attrs.id) {
    return document.querySelector(`#${attrs.id} > .tab-button:nth-child(${index + 1})`)
  }

  return document.querySelector(`.tab-button:nth-child(${index + 1})`)
}

function updateUnderlinePosition(index: number) {
  const tabElement = getTabElement(index)
  if (!(tabElement instanceof HTMLElement)) return
  underlineStyle.value = {
    width: `${tabElement.offsetWidth}px`,
    transform: `translateX(${tabElement.offsetLeft}px)`,
  }
}

function setCurrentTab(index: number) {
  updateUnderlinePosition(index)
  if (props.tabName != 'instance-modal-tabs') {
    router.replace({
      query: {
        ...route.query,
        [props.tabName]: index,
      },
    })
  }

  emit('update:modelValue', index)
}

function init() {
  const param = props.modelValue ?? Number.parseInt(getQueryParam(props.tabName))
  emit('update:modelValue', param ?? 0)
}
onMounted(() => {
  init()
  updateUnderlinePosition(props.modelValue ?? 0)
})
</script>

<template>
  <nav class="flex gap-6 overflow-x-auto relative" :class="props.class">
    <template v-for="(tab, index) in props.listTabs" :key="index">
      <button
        class="tab-button group pb-2 text-sm cursor-pointer whitespace-nowrap"
        :class="[props.modelValue === index ? 'text-secondary font-medium' : 'text-gray-600 hover:text-blue-500']"
        @click="setCurrentTab(index)"
      >
        <slot :name="index" :tab="tab">
          {{ tab }}
        </slot>
      </button>
    </template>
    <div class="absolute bottom-0 h-0.5 bg-blue-500 transition-all duration-300" :style="underlineStyle" />
  </nav>
</template>

<style scoped>
.tab-button {
  position: relative;
}
</style>
