import { AxiosInstance, AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios'
import { i18n } from '~/modules/i18n'

const t = i18n?.global?.t

export interface Filters {
  status?: string
  name?: string
}

export interface ApiResponse<T> {
  data: T
}

interface MetaLink {
  active: boolean
  label: string
  url: string | null
}

export interface ApiPaginatedResponse<T> {
  data: T
  links: {
    first: string
    last: string
    prev: string | null
    next: string | null
  }
  meta: {
    current_page: number
    from: number | null
    to: number | null
    total: number
    last_page: number
    path: string
    per_page: number
    links: MetaLink[]
  }
  auth: {
    can: {
      create?: boolean
      update?: boolean
      delete?: boolean
      see?: boolean
    }
  }
}

function hasToStringMethod(value: unknown): value is { toString: () => string } {
  return typeof value === 'object' && value !== null && 'toString' in value
}

export function unwrapApiErrors(error: unknown): string {
  if (isAxiosError<{ message: string }>(error)) {
    if (error.response?.status === 413) {
      return t('global.file_too_large_error')
    }
    return error.response?.data.message ?? error.toString()
  }

  if (error instanceof Error) {
    return error.message
  }

  if (hasToStringMethod(error)) {
    return error.toString()
  }

  return 'An error occurred'
}

function createRequest<R, D>({
  method,
}: {
  method: Exclude<AxiosRequestConfig['method'], 'get' | 'delete' | 'GET' | 'DELETE'>
}) {
  return function (client: AxiosInstance, config: AxiosRequestConfig) {
    return function (url: string, data: D) {
      return client.request<ApiResponse<R>, AxiosResponse<ApiResponse<R>>, D>({ ...config, method, url, data })
    }
  }
}

export function postRequest<R, D>(client: AxiosInstance, config: AxiosRequestConfig) {
  return createRequest<R, D>({ method: 'post' })(client, config)
}

export function postRawRequest<R, D>(client: AxiosInstance, config: AxiosRequestConfig) {
  return function (url: string, data: D) {
    return client.post<R, AxiosResponse<R>, D>(url, data, config)
  }
}

export function putRequest<R, D>(client: AxiosInstance, config: AxiosRequestConfig) {
  return createRequest<R, D>({ method: 'put' })(client, config)
}

export function patchRequest<R, D>(client: AxiosInstance, config: AxiosRequestConfig) {
  return createRequest<R, D>({ method: 'patch' })(client, config)
}

export function getRequest<R>(client: AxiosInstance, config: AxiosRequestConfig) {
  return function (url: string) {
    return client.get<ApiResponse<R>>(url, config)
  }
}

export function getRawRequest<R>(client: AxiosInstance, config: AxiosRequestConfig) {
  return function (url: string) {
    return client.get<R>(url, config)
  }
}

export function getPaginatedRequest<R>(client: AxiosInstance, config: AxiosRequestConfig) {
  return function (url: string) {
    return client.get<ApiPaginatedResponse<R>>(url, config)
  }
}

export function deleteRequest<R>(client: AxiosInstance, config: AxiosRequestConfig) {
  return function (url: string) {
    return client.delete<ApiResponse<R>>(url, config)
  }
}
